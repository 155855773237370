import React, { useContext } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import icon from "../../assets/icon/RGFAN.png"

import { SidebarContext, MobileSidebarContext } from "../../context";
import "./style.scss";

export default function MyIcon() {
  const { sidebarState } = useContext(SidebarContext);
  const { mobileSidebarState } = useContext(MobileSidebarContext);
  return (
    <Link to="/">
      <div className={classNames("my_icon", {sidebar_active: sidebarState}, { mobile_sidebar_active: mobileSidebarState })}>
        <div className="my_icon__dynamic">
          <div className="my_icon__dynamic__wrap">
            <img src={ icon } alt="web icon" />
          </div>
          <p className="my_icon__dynamic__title">
            Roger Fan's Learning Journey
          </p>
        </div>
      </div>
    </Link>
  )
}